import React from 'react';
import { Block } from 'baseui/block';
import { Display1, Paragraph1, Paragraph2, Paragraph3 } from 'baseui/typography';
import Layout from 'components/layout';

export default () => {
	return (
    <Layout>
      <Block>
				<Paragraph1>TERMS OF SERVICE</Paragraph1>

				<Paragraph3>
					PLEASE READ THIS TERMS OF USE AGREEMENT (THE <strong>“TERMS”</strong>)
					CAREFULLY. BY ACCESSING, BROWSING OR USING THE One More Watches INC. (“
					<strong>One More Watches</strong>") WEBSITE AVAILABLE AT
					WWW.onemorewatches.com OR ANY WEBSITE WITH LINKS TO THIS AGREEMENT (THE{' '}
					<strong>“WEBSITE”</strong>) IN ANY WAY OR CLICKING AN “I ACCEPT” OR
					SIMILAR BUTTON, YOU REPRESENT THAT (1) YOU HAVE READ, UNDERSTAND, AND
					AGREE TO BE BOUND BY THE TERMS, (2) YOU ARE OF LEGAL AGE TO FORM A
					BINDING CONTRACT WITH ONEMOREWATCHES, AND (3) YOU HAVE THE AUTHORITY TO ENTER
					INTO THE TERMS ON BEHALF OF YOURSELF OR THE COMPANY YOU WORK FOR, AND
					TO BIND THAT COMPANY TO THESE TERMS. <strong>IF YOU DO N</strong>
					<strong>
						OT AGREE TO BE BOUND BY THE TERMS, YOU MAY NOT ACCESS OR USE THIS
						WEBSITE
					</strong>
					.
				</Paragraph3>

				<Paragraph3>
					<strong>
						THESE TERMS INCLUDE A CLASS ACTION WAIVER AND A WAIVER OF JURY
						TRIALS, AND REQUIRE BINDING ARBITRATION ON AN INDIVIDUAL BASIS TO
						RESOLVE MOST DISPUTES.
					</strong>
				</Paragraph3>

				<Paragraph3>
					<strong>
						THESE TERMS LIMIT THE REMEDIES THAT MAY BE AVAILABLE TO YOU IN THE
						EVENT OF A DISPUTE.
					</strong>
				</Paragraph3>

				<Paragraph3>
					PLEASE NOTE THAT The Terms are subject to change by ONEMOREWATCHES in its sole
					discretion at any time. When changes are made, One More Watches will make
					a new copy of the Terms available at the Website. If we make any
					material changes, and you have provided us with your e-mail address,
					we will also send an e-mail to you at the last e-mail address you
					provided to us. Any changes to the Terms will be effective immediately
					for new users of the Website and will be effective for existing users
					upon the earlier of thirty (30) days after posting of notice of such
					changes on the Website or thirty (30) days after dispatch of an e-mail
					notice of such changes. One More Watches may require you to provide
					consent to the updated Terms in a specified manner before further use
					of the Website is permitted. If you do not agree to any change(s)
					after receiving a notice of such change(s), you shall stop using the
					Website. Otherwise, your continued use of the Website constitutes your
					acceptance of such change(s). PLEASE REGULARLY CHECK THE WEBSITE TO
					VIEW THE THEN-CURRENT TERMS.
				</Paragraph3>

				<ul>
					<li>
						<strong>USE OF One More Watches PROPERTIES.</strong>  The Website and
						the content and information available on the Website (“
						<strong>One More Watches PROPERTIES</strong>”) are protected by
						copyright laws throughout the world. Subject to the Terms, ONEMOREWATCHES
						Watches grants you a limited license to reproduce portions of ONEMOREWATCHES
						Watches Properties for the sole purpose of using the Website for
						your personal purposes. Unless otherwise specified by One More Watches
						in a separate license, your right to use any One More Watches Properties
						is subject to the Terms.
						<ul>
							<li>
								<strong>  1.1  </strong>&nbsp;
								<strong>
									<u>CERTAIN RESTRICTIONS</u>.  
								</strong>{' '}
								The rights granted to you in the Terms are subject to the
								following restrictions: (a) you shall not license, sell, rent,
								lease, transfer, assign, reproduce, distribute, host or
								otherwise commercially exploit One More Watches Properties or any
								portion of One More Watches Properties; (b) you shall not frame or
								utilize framing techniques to enclose any trademark, logo, or
								other One More Watches Properties (including images, text, page
								layout or form) of One More Watches; (c) you shall not use any
								metatags or other “hidden text” using One More Watches name or
								trademarks; (d) you shall not modify, translate, adapt, merge,
								make derivative works of, disassemble, decompile, reverse
								compile or reverse engineer any part of One More Watches Properties
								except to the extent the foregoing restrictions are expressly
								prohibited by applicable law; (e) you shall not use any manual
								or automated software, devices or other processes (including but
								not limited to spiders, robots, scrapers, crawlers, avatars,
								data mining tools or the like) to “scrape” or download data from
								any web pages contained in the Website (except that we grant the
								operators of public search engines revocable permission to use
								spiders to copy materials from the Website for the sole purpose
								of and solely to the extent necessary for creating publicly
								available searchable indices of the materials, but not caches or
								archives of such materials); (f) you shall not access ONEMOREWATCHES
								Watches Properties in order to build a similar or competitive
								website, application or service; and (g) except as expressly
								stated herein, no part of One More Watches Properties may be copied,
								reproduced, distributed, republished, downloaded, displayed,
								posted or transmitted in any form or by any means. Any future
								release, update or other addition to One More Watches Properties
								shall be subject to the Terms. One More Watches, its suppliers and
								service providers reserve all rights not granted in the Terms.
								Any unauthorized use of One More Watches Properties terminates the
								licenses granted by One More Watches pursuant to the Terms.
								<ul></ul>
							</li>
						</ul>
					</li>
					<li>
						<strong>REGISTRATION</strong>
						<ul>
							<li>
								<strong>  2.1  </strong>
								<strong>
									<u>REGISTERING YOUR ACCOUNT</u>
								</strong>
								<strong>.  </strong>In order to access certain features of ONEMOREWATCHES
								Watches Properties you will be required to become a Registered
								User. For purposes of the Terms, a{' '}
								<strong>“REGISTERED USER”</strong> is a user who creates an
								account with One More Watches (“<strong>ACCOUNT</strong>”) by either
								registering directly with One More Watches through the Website or
								logging into the Website with the user’s social networking
								service account (<strong>“THIRD-PARTY ACCOUNT”</strong>). By
								logging in with your Third-Party Account, you represent that you
								are entitled to disclose your Third-Party Account login
								information to One More Watches and/or grant One More Watches access to
								your Third-Party Account (including, but not limited to, for use
								for the purposes described herein) without breach by you of any
								of the terms and conditions that govern your use of the
								applicable Third-Party Account and without obligating ONEMOREWATCHES
								Watches to pay any fees or making One More Watches subject to any
								usage limitations imposed by such third-party service providers.
								By granting One More Watches access to any Third-Party Accounts, you
								understand that One More Watches may access, make available and
								store (if applicable) any information, photographs, and/or other
								materials accessible through One More Watches Properties (
								<strong>“CONTENT”</strong>) that you have provided to and stored
								in your Third-Party Account (
								<strong>“THIRD-PARTY ACCOUNT CONTENT”</strong>) so that it is
								available on and through One More Watches Properties via your
								Account. Unless otherwise specified in the Terms, all
								Third-Party Account Content shall be considered to be Your
								Content for all purposes of the Terms. Subject to the privacy
								settings that you have set in your Third-Party Account,
								personally identifiable information that you post to your
								Third-Party Account may be available on and through your Account
								on One More Watches Properties. Please note that if a Third-Party
								Account or associated service becomes unavailable or ONEMOREWATCHES
								Watches access to such Third-Party Account is terminated by the
								third-party service provider, then Account Content may no longer
								be available on and through One More Watches Properties. PLEASE NOTE
								THAT YOUR RELATIONSHIP WITH THE THIRD-PARTY SERVICE PROVIDER
								ASSOCIATED WITH YOUR THIRD-PARTY ACCOUNT IS GOVERNED SOLELY BY
								YOUR AGREEMENT(S) WITH SUCH THIRD-PARTY SERVICE PROVIDER, AND
								One More Watches DISCLAIMS ANY LIABILITY FOR PERSONALLY IDENTIFIABLE
								INFORMATION THAT MAY BE PROVIDED TO IT BY SUCH THIRD-PARTY
								SERVICE PROVIDER IN VIOLATION OF THE PRIVACY SETTINGS THAT YOU
								HAVE SET IN SUCH THIRD-PARTY ACCOUNT. One More Watches makes no
								effort to review any Content for any purpose, including but not
								limited to, for accuracy, legality or noninfringement, and ONEMOREWATCHES
								Watches is not responsible for any Third-Party Account Content.
							</li>
							<li>
								<strong>  2.2  </strong>
								<strong>
									<u>REGISTRATION DATA</u>
								</strong>
								<strong>.  </strong>In registering for the Services, you agree
								to (1) provide true, accurate, current and complete information
								about yourself (the <strong>“REGISTRATION DATA”</strong>); and
								(2) maintain and promptly update the Registration Data to keep
								it true, accurate, current and complete. You represent that you
								are at least thirteen (13) years old (or such other minimum age
								of requirement as may be applicable in your jurisdiction) and
								not a person barred from using One More Watches Properties under the
								laws of the United States, your place of residence or any other
								applicable jurisdiction. You are responsible for all activities
								that occur under your Account. You may not share your Account or
								password with anyone, and you agree to notify One More Watches
								immediately of any unauthorized use of your password or any
								other breach of security and exit from your Account at the end
								of each session. If you provide any information that is untrue,
								inaccurate, not current or incomplete, or One More Watches has
								reasonable grounds to suspect that such information is untrue,
								inaccurate, not current or incomplete, One More Watches has the
								right to suspend or terminate your Account and refuse any and
								all current or future use of One More Watches Properties (or any
								portion thereof). You agree not to create an Account using a
								false identity or information, or on behalf of someone other
								than yourself. You agree that you shall not have more than one
								Account at any given time. You agree not to create an Account or
								use One More Watches Properties if you have been previously removed
								by One More Watches, or if you have been previously banned from any
								of One More Watches Properties.
							</li>
							<li>
								<strong>  2.3  </strong>
								<strong>
									<u>YOUR ACCOUNT</u>.  
								</strong>{' '}
								Notwithstanding anything to the contrary herein, you acknowledge
								and agree that you shall have no ownership or other property
								interest in your Account, and you further acknowledge and agree
								that all rights in and to your Account are and shall forever be
								owned by and inure to the benefit of One More Watches.
								<ul></ul>
							</li>
						</ul>
					</li>
					<li>
						<strong>ORDER AND ACCEPTANCE.</strong>
						<ul>
							<li>
								<strong>  3.1  </strong>
								<strong>
									<u>ORDER ACCEPTANCE PROCESS</u>. {' '}
								</strong>
								Each part of an order that you submit to One More Watches
								constitutes an offer to purchase merchandise. If you have placed
								your order through the Website, then, after One More Watches
								receives your order, you will receive an email from One More Watches
								confirming receipt of your order (usually in minutes). If you do
								not receive an email from One More Watches confirming receipt of an
								order that you placed, then please contact the One More Watches
								Customer Service department at
								http://www.onemorewatches.com/contact before you attempt to
								place another order for the same merchandise. Please note that
								One More Watches confirmation of receipt of your order does not
								equate to One More Watches acceptance of your order. One More Watches is
								not deemed to have accepted any part of your order until the
								requested merchandise has been shipped and One More Watches has sent
								a shipment confirmation email.
							</li>
							<li>
								<strong>  3.2  </strong>
								<strong>
									<u>ORDER ISSUES</u>
								</strong>
								.
								<ul>
									<li>
										<strong>(A)  </strong>If One More Watches rejects your order due
										to an error in pricing and/or other information about the
										merchandise, then One More Watches will notify you at the email
										address that you have provided. Once the error has been
										corrected, One More Watches will ask you whether or not you
										would like to re-submit your order to purchase the relevant
										merchandise. If One More Watches has notified you that your
										order has been accepted by sending you a shipment
										confirmation email, then you will not be required to pay a
										price that is greater than the price that was displayed on
										the Website at the time at which you placed your order. If
										One More Watches has distributed merchandise that is different
										from the description of the relevant merchandise (as
										displayed on the Website at the time at which you placed
										your order), then you may request a return or an exchange.
										For additional details, please review the One More Watches
										Returns Policy available at
										http://www.onemorewatches.com/returns.
									</li>
									<li>
										<strong>(B)  </strong>Although it is unlikely that ONEMOREWATCHES
										Watches would refuse to accept an order, One More Watches
										reserves the right to deny any order for any reason,
										including where the following situations arise: (i)
										insufficient information or errors in billing, payment,
										and/or shipping information; (ii) orders that cannot be
										processed due to erroneous information that you have
										provided, which includes, but is not limited to incorrect
										credit card or debit card number, expiration date, security
										value, or other incorrect information regarding payment
										types; (iii) suspected fraudulent information; or (iv)
										delayed shipment or unavailability of merchandise.
									</li>
									<li>
										<strong>(C)  </strong>One More Watches may refuse to accept any
										order if fraudulent activity is suspected. One More Watches may
										refuse to process any subsequent order from a customer who
										has a history of placing fraudulent orders.
									</li>
									<li>
										<strong>(D)  </strong>One More Watches may refuse any order that
										is connected with a previous credit card dispute.
									</li>
								</ul>
							</li>
							<li>
								<strong>  3.3  </strong>
								<strong>
									<u>ORDER CANCELLATION</u>. {' '}
								</strong>
								If an item of merchandise is delayed out of the One More Watches
								fulfillment center or becomes unavailable, or if there is an
								error on the Website pertaining to the order (e.g. an error
								pertaining to the price and/or description of merchandise), then
								One More Watches may cancel the order. If this occurs, then ONEMOREWATCHES
								Watches will contact you so that you are aware of the situation.
							</li>
							<li>
								<strong>  3.4  </strong>
								<strong>
									<u>RESTRICTIONS ON RESALE</u>. {' '}
								</strong>
								In order to protect One More Watches intellectual property rights,
								any suspected resale of merchandise for personal and/or business
								profit is strictly prohibited. One More Watches will not accept any
								order that is deemed to possess characteristics of reselling.
								One More Watches reserves the right to cancel any subsequent order
								from a customer who has been suspected of reselling.
							</li>
						</ul>
					</li>
					<li>
						<strong>PAYMENT TERMS.</strong>
						<ul>
							<li>
								<strong>  4.1  </strong>
								<strong>
									<u>PAYMENT</u>.  
								</strong>
								All prices are quoted in the denomination stated. To make a
								purchase, you must provide a valid credit card (Visa,
								MasterCard, or any other issuer accepted by us) (
								<strong>“PAYMENT PROVIDER”</strong>). Your Payment Provider
								agreement governs your use of the designated credit card, and
								you must refer to that agreement and not the Terms to determine
								your rights and liabilities. By providing One More Watches with your
								credit card number and associated payment information, you
								hereby authorize One More Watches to immediately charge your credit
								card for all fees and charges due and payable to One More Watches
								hereunder or credit your credit card for any refunds owed and
								that no additional notice or consent is required. You agree to
								immediately notify One More Watches of any change in your billing
								address or the credit card used for payment hereunder. ONEMOREWATCHES
								Watches reserves the right at any time to change its prices and
								billing methods, either immediately upon posting on the ONEMOREWATCHES
								Watches Properties or by e-mail delivery to you.
							</li>
							<li>
								<strong>  4.2  </strong>
								<strong>
									<u>REFUNDS</u>.  
								</strong>
								Payments made to One More Watches are subject to refund in
								accordance with One More Watches refund policy available at
								http://www.onemorewatches.com/returns.
							</li>
							<li>
								<strong>
									  4.3  <u>TAXES</u>.  
								</strong>
								One More Watches fees are net of any applicable Sales Tax. If any
								products or Services, or payments for any products or Services,
								under the Terms are subject to Sales Tax in any jurisdiction and
								you have not remitted the applicable Sales Tax to One More Watches,
								you will be responsible for the payment of such Sales Tax and
								any related penalties or interest to the relevant tax authority
								and you will indemnify One More Watches for any liability or expense
								we may incur in connection with such Sales Taxes. Upon our
								request, you will provide us with official receipts issued by
								the appropriate taxing authority, or other such evidence that
								you have paid all applicable taxes. For purposes of this
								section, <strong>“SALES TAX”</strong> will mean any sales or use
								tax, and any other tax measured by sales proceeds, that ONEMOREWATCHES
								Watches is permitted to pass to its customers that is) the
								functional equivalent of a sales tax where the applicable taxing
								jurisdiction does not otherwise impose a sales or use tax.
							</li>
							<li>
								<strong>  4.4  </strong>
								<strong>
									<u>DISCOUNTS AND PROMO CODES</u>.  
								</strong>
								We may, in our sole discretion, create discounts and promotional
								codes that may be redeemed for credit in your Account, or other
								features or benefits, subject to any additional terms that we
								establish on a per promotional code basis (“
								<strong>PROMO CODES</strong>”). Promo Codes may only be used
								once per person. Only Promo Codes sent to you through official
								One More Watches communications channels are valid. You agree that
								Promo Codes: (i) must be used for the intended audience and
								purpose, and in a lawful manner; (ii) may not be duplicated,
								sold, or transferred in any manner, or made available to the
								general public (whether posted to a public form or otherwise),
								unless expressly permitted by us; (iii) may be disabled by us at
								any time for any reason without liability to us; (iv) may only
								be used pursuant to the specific terms that we establish for
								such Promo Code; (v) are not valid for cash; and (vi) may expire
								prior to your use.
							</li>
						</ul>
					</li>
					<li>
						<strong>THIRD PARTY PAYMENT SERVICES PROVIDER.  </strong> ONEMOREWATCHES
						Watches uses Stripe Inc. (“<strong>STRIPE</strong>”) as a third party service providers for
						payment services (e.g., credit card transaction processing, merchant
						settlement, and related services). By using the One More Watches
						Properties, you agree to be bound by Stripe’s US Terms of Service
						available at{' '}
						<a href="https://stripe.com/us/terms">
							HTTPS://STRIPE.COM/US/TERMS
						</a>{' '}
						and Privacy Policy available at{' '}
						<a href="https://stripe.com/us/privacy">
							HTTPS://STRIPE.COM/US/PRIVACY
						</a>
						as applicable. You hereby consent to provide and authorize ONEMOREWATCHES
						Watches, Stripe to share any information and
						payment instructions you provide to the extent required to complete
						the payment transactions in accordance with the Terms, including
						personal, financial, credit card payment, and transaction
						information.
					</li>
					<li>
						<strong>RESPONSIBILITY FOR CONTENT.</strong>
						<ul>
							<li>
								<strong>  6.1  </strong>
								<strong>
									<u>TYPES OF CONTENT</u>
								</strong>
								<strong>.  </strong> You acknowledge that all Content, including
								One More Watches Properties, is the sole responsibility of the party
								from whom such Content originated. This means that you, and not
								One More Watches, are entirely responsible for all Content that you
								upload, post, e-mail, transmit or otherwise make available (
								<strong>“MAKE AVAILABLE”</strong>) through One More Watches
								Properties (<strong>“YOUR CONTENT”</strong>), and that you and
								other Users of One More Watches Properties, and not One More Watches,
								are similarly responsible for all Content they Make Available
								through One More Watches Properties (<strong>“USER CONTENT”</strong>
								).
							</li>
							<li>
								<strong>  6.2  </strong>
								<strong>
									<u>NO OBLIGATION TO PRE-SCREEN CONTENT</u>.  
								</strong>{' '}
								You acknowledge that One More Watches has no obligation to
								pre-screen Content (including, but not limited to, User
								Content), although One More Watches reserves the right in its sole
								discretion to pre-screen, refuse or remove any Content. By
								entering into the Terms, you hereby provide your irrevocable
								consent to such monitoring. You acknowledge and agree that you
								have no expectation of privacy concerning the transmission of
								Your Content, including without limitation chat, text, or voice
								communications. In the event that One More Watches pre-screens,
								refuses or removes any Content, you acknowledge that ONEMOREWATCHES
								Watches will do so for One More Watches benefit, not yours. Without
								limiting the foregoing, One More Watches shall have the right to
								remove any Content that violates the Terms or is otherwise
								objectionable.
							</li>
						</ul>
					</li>
					<li>
						<strong>OWNERSHIP.</strong>
						<ul>
							<li>
								<strong>
									  7.1  
									<u>
										One More Watches
										<u>
											<strong> </strong>
										</u>
										PROPERTIES
									</u>
									.  
								</strong>{' '}
								You agree that One More Watches and its licensors and suppliers own
								all rights, title and interest in One More Watches Properties. You
								will not remove, alter or obscure any copyright, trademark,
								service mark or other proprietary rights notices incorporated in
								or accompanying One More Watches Properties.
							</li>
							<li>
								<strong>  7.2  </strong>
								<strong>
									<u>TRADEMARKS</u>.  
								</strong>{' '}
								One More Watches stylized name and other related graphics, logos,
								service marks and trade names used on or in connection with ONEMOREWATCHES
								Watches Properties are the trademarks of One More Watches and may
								not be used without permission in connection with any
								third-party products or services. Other trademarks, service
								marks and trade names that may appear on or in One More Watches
								Properties are the property of their respective owners.
							</li>
							<li>
								<strong>  7.3  </strong>
								<strong>
									<u>OTHER CONTENT</u>
								</strong>
								<strong>.  </strong> Except with respect to Your Content, you
								agree that you have no right or title in or to any Content that
								appears on or in One More Watches Properties.
							</li>
							<li>
								<strong>  7.4  </strong>
								<strong>
									<u>YOUR CONTENT</u>
								</strong>
								<strong>.  </strong> One More Watches does not claim ownership of
								Your Content. However, when you as a user post or publish Your
								Content on or in One More Watches Properties, you represent that you
								own and/or have a royalty-free, perpetual, irrevocable,
								worldwide, non-exclusive right (including any moral rights) and
								license to use, license, reproduce, modify, adapt, publish,
								translate, create derivative works from, distribute, derive
								revenue or other remuneration from, and communicate to the
								public, perform and display Your Content (in whole or in part)
								worldwide and/or to incorporate it in other works in any form,
								media or technology now known or later developed, for the full
								term of any worldwide intellectual property right that may exist
								in Your Content.
							</li>
							<li>
								<strong>  7.5  </strong>
								<strong>
									<u>LICENSE TO YOUR CONTENT</u>
								</strong>
								<strong>.  </strong> Subject to any applicable account settings
								that you select, you grant One More Watches a fully paid,
								royalty-free, perpetual, irrevocable, worldwide, royalty-free,
								non-exclusive and fully sublicensable right (including any moral
								rights) and license to use, license, distribute, reproduce,
								modify, adapt, publicly perform, and publicly display, Your
								Content (in whole or in part) for the purposes of operating and
								providing One More Watches Properties to you and to our other users.
								Please remember that other users may search for, see, use,
								modify and reproduce any of Your Content that you submit to any
								“public” area of One More Watches Properties. You warrant that the
								holder of any worldwide intellectual property right, including
								moral rights, in Your Content, has completely and effectively
								waived all such rights and validly and irrevocably granted to
								you the right to grant the license stated above
								<strong>. </strong>You agree that you, not One More Watches, are
								responsible for all of Your Content that you Make Available on
								or in One More Watches Properties.
							</li>
						</ul>
					</li>
					<li>
						<strong>FEEDBACK</strong>.   You agree that submission of any ideas,
						suggestions, documents, and/or proposals to One More Watches (
						<strong>“FEEDBACK”</strong>) is at your own risk and that ONEMOREWATCHES
						Watches has no obligations (including without limitation obligations
						of confidentiality) with respect to such Feedback. You represent and
						warrant that you have all rights necessary to submit the Feedback.
						You hereby grant to One More Watches a fully paid, royalty-free,
						perpetual, irrevocable, worldwide, non-exclusive, and fully
						sublicensable right and license to use, reproduce, perform, display,
						distribute, adapt, modify, re-format, create derivative works of,
						and otherwise commercially or non-commercially exploit in any
						manner, any and all Feedback, and to sublicense the foregoing
						rights, in connection with the operation and maintenance of ONEMOREWATCHES
						Watches Properties.
					</li>
					<li>
						<strong>USER CONDUCT.  </strong> As a condition of use, you agree
						not to use One More Watches Properties for any purpose that is
						prohibited by the Terms or by applicable law. You shall not (and
						shall not permit any third-party) either (a) to take any action or
						(b) Make Available any Content on or through One More Watches Properties
						that: (i) infringes any patent, trademark, trade secret, copyright,
						right of publicity or other right of any person or entity; (ii) is
						unlawful, threatening, abusive, harassing, defamatory, libelous,
						deceptive, fraudulent, invasive of another’s privacy, tortious,
						obscene, offensive, or profane; (iii) constitutes unauthorized or
						unsolicited advertising, junk or bulk e-mail; (iv) involves
						commercial activities and/or sales without One More Watches prior
						written consent, such as contests, sweepstakes, barter, advertising,
						or pyramid schemes; (v) impersonates any person or entity, including
						any employee or representative of One More Watches; (vi) interferes with
						or attempts to interfere with the proper functioning of One More Watches
						Properties or uses One More Watches Properties in any way not expressly
						permitted by the Terms; or (vii) to attempt or engage in, any
						potentially harmful acts that are directed against One More Watches
						Properties, including but not limited to violating or attempting to
						violate any security features of One More Watches Properties,
						introducing viruses, worms, or similar harmful code into ONEMOREWATCHES
						Watches Properties, or interfering or attempting to interfere with
						use of One More Watches Properties by any other user, host or network,
						including by means of overloading, “flooding,” “spamming,” “mail
						bombing”, or “crashing” One More Watches Properties.
					</li>
					<li>
						<strong>INTERACTIONS WITH OTHER USERS.</strong>
						<ul>
							<li>
								<strong>  10.1  </strong>
								<strong>
									<u>USER RESPONSIBILITY</u>
								</strong>
								<strong>.  </strong>You are solely responsible for your
								interactions with other users of the Services and any other
								parties with whom you interact through the Services; provided,
								however, that One More Watches reserves the right, but has no
								obligation, to intercede in such disputes. You agree that ONEMOREWATCHES
								Watches will not be responsible for any liability incurred as
								the result of such interactions.
							</li>
							<li>
								<strong>  10.2  </strong>
								<strong>
									<u>CONTENT PROVIDED BY OTHER USERS</u>.  
								</strong>{' '}
								One More Watches Properties may contain User Content provided by
								other users. One More Watches is not responsible for and does not
								control User Content. One More Watches has no obligation to review
								or monitor, and does not approve, endorse or make any
								representations or warranties with respect to User Content. You
								use all User Content and interact with other users at your own
								risk.
							</li>
						</ul>
					</li>
					<li>
						<strong>THIRD-PARTY WEBSITES.  </strong> One More Watches Properties may
						contain links to third-party websites (
						<strong> “THIRD-PARTY WEBSITES”</strong>). When you click on a link
						to a third-party website, we will not warn you that you have left
						One More Watches Properties and are subject to the terms and conditions
						(including privacy policies) of another website or destination. Such
						Third-Party Websites are not under the control of One More Watches. ONEMOREWATCHES
						Watches is not responsible for any Third-Party Websites. ONEMOREWATCHES
						Watches provides these Third-Party Websites only as a convenience
						and does not review, approve, monitor, endorse, warrant, or make any
						representations with respect to Third-Party Websites, or their
						products or services. You use all links in Third-Party Websites at
						your own risk. When you leave our Website, our Terms and policies no
						longer govern. You should review applicable terms and policies,
						including privacy and data gathering practices, of any third-party
						websites, and should make whatever investigation you feel necessary
						or appropriate before proceeding with any transaction with any
						third-party.
					</li>
					<li>
						<strong>INVESTIGATIONS.  </strong> One More Watches may, but is not
						obligated to, monitor or review the Website at any time. Without
						limiting the foregoing, One More Watches shall have the right, in its
						sole discretion, to remove any content for any reason (or no
						reason), including if such content violates the Terms or any
						applicable law. Although One More Watches does not generally monitor
						user activity, if One More Watches becomes aware of any possible
						violations by a user of any provision of the Terms, One More Watches
						reserves the right to investigate such violations, and One More Watches
						may, at its sole discretion, immediately terminate the user’s
						license to use the One More Watches Properties, or change, alter or
						remove any content, in whole or in part, without prior notice.
					</li>
					<li>
						<strong>PRIVACY.  </strong>You agree to the terms of our privacy
						policy available at http://www.onemorewatches.com/privacy-policy.
					</li>
					<li>
						<strong>INDEMFICATION.  </strong>You agree to indemnify and hold the
						One More Watches Parties harmless from any losses, costs, liabilities
						and expenses (including reasonable attorneys’ fees) relating to or
						arising out of: (a) your use of, or inability to use, One More Watches
						Properties; (b) your violation of the Terms; (c) your violation of
						any rights of another party; or (d) your violation of any applicable
						laws, rules or regulations. One More Watches reserves the right, at its
						own cost, to assume the exclusive defense and control of any matter
						otherwise subject to indemnification by you, in which event you will
						fully cooperate with One More Watches in asserting any available
						defenses. You agree that the provisions in this section will survive
						any termination of the Terms or your access to One More Watches
						Properties.
					</li>
					<li>
						<strong>DISCLAIMER OF WARRANTIES.  </strong>YOU EXPRESSLY UNDERSTAND
						AND AGREE THAT TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW,
						YOUR USE OF One More Watches PROPERTIES IS AT YOUR SOLE RISK, AND ONEMOREWATCHES
						Watches PROPERTIES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE”
						BASIS, WITH ALL FAULTS. One More Watches PARTIES EXPRESSLY DISCLAIM ALL
						WARRANTIES, REPRESENTATIONS, AND CONDITIONS OF ANY KIND, WHETHER
						EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED
						WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A
						PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT. One More Watches
						PARTIES MAKE NO WARRANTY, REPRESENTATION OR CONDITION THAT: (1) ONEMOREWATCHES
						Watches PROPERTIES WILL MEET YOUR REQUIREMENTS; (2) YOUR USE OF ONEMOREWATCHES
						Watches PROPERTIES WILL BE UNINTERRUPTED, TIMELY, SECURE OR
						ERROR-FREE; OR (3) ANY ERRORS IN One More Watches PROPERTIES WILL BE
						CORRECTED. CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED
						WARRANTIES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE FOREGOING
						DISCLAIMERS, EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO YOU, AND
						YOU MIGHT HAVE ADDITIONAL RIGHTS.
					</li>
					<li>
						<strong>LIMITATION OF LIABILITY.  </strong>TO THE FULLEST EXTENT
						PERMITTED BY APPLICABLE LAW, YOU UNDERSTAND AND AGREE THAT IN NO
						EVENT SHALL ANY One More Watches PARTIES BE LIABLE FOR ANY INDIRECT,
						INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES ARISING OUT
						OF OR IN CONNECTION WITH One More Watches PROPERTIES OR ANY DAMAGES
						RESULTING FROM LOSS OF USE, DATA, OR PROFITS, WHETHER OR NOT ONEMOREWATCHES
						Watches HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, OR FOR
						ANY DAMAGES FOR PERSONAL OR BODILY INJURY OR EMOTIONAL DISTRESS
						ARISING OUT OF OR IN CONNECTION WITH THE TERMS, ON ANY THEORY OF
						LIABILITY, RESULTING FROM: (1) THE USE OR INABILITY TO USE ONEMOREWATCHES
						Watches PROPERTIES OR (2) ANY OTHER MATTER RELATED TO One More Watches
						PROPERTIES, WHETHER BASED ON WARRANTY, COPYRIGHT, CONTRACT, TORT
						(INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY.
						TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, OUR AGGREGATE
						CUMULATIVE LIABILITY TO YOU ARISING OUT OF OR RELATED TO ONEMOREWATCHES
						Watches PROPERTIES SHALL IN NO EVENT EXCEED THE GREATER OF (A) THE
						AMOUNT RECEIVED BY One More Watches AS A RESULT OF YOUR USE OF ONEMOREWATCHES
						Watches PROPERTIES OR (B) ONE HUNDRED DOLLARS ($100). YOU AND ONEMOREWATCHES
						Watches AGREE THAT ANY CAUSE OF ACTION ARISING OUT OF OR RELATED TO
						THE TERMS, One More Watches PROPERTIES OR ANY CONTENT POSTED ON ONEMOREWATCHES
						Watches PROPERTIES MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE
						OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF ACTION IS PERMANENTLY
						BARRED. THE LIMITATIONS OF DAMAGES SET FORTH ABOVE ARE FUNDAMENTAL
						ELEMENTS OF THE BASIS OF THE BARGAIN BETWEEN One More Watches AND YOU.
						THE LAWS OF SOME STATES DO NOT ALLOW FOR THE EXCLUSION OR LIMITATION
						OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE
						FOREGOING DISCLAIMERS, EXCLUSIONS AND LIMITATIONS MAY NOT APPLY TO
						YOU AND YOU MIGHT HAVE OTHER RIGHTS.
					</li>
					<li>
						<strong>TERM AND TERMINATION.</strong>
						<ul>
							<li>
								<strong>  18.1  </strong>
								<strong>
									<u>TERM</u>.  
								</strong>{' '}
								The Terms commence on the date when you accept them (as
								described in the preamble above) and remain in full force and
								effect while you use One More Watches Properties, unless terminated
								earlier in accordance with the Terms.
							</li>
							<li>
								<strong>  18.2  </strong>
								<strong>
									<u>TERMINATION</u>.  
								</strong>
								If timely payment cannot be charged to your Payment Provider for
								any reason, if you have materially breached any provision of the
								Terms, if we are required to do so by law (e.g., where the
								provision of any of One More Watches Properties is, or becomes,
								unlawful), or if we choose to discontinue One More Watches
								Properties (in part or in whole), we have the right to,
								immediately and without notice, suspend or terminate any ONEMOREWATCHES
								Watches Properties provided to you. If we become aware of any
								possible violations by you of the Terms, we reserve the right to
								investigate such violations. In the event that we determine, in
								our sole discretion, that you have breached any portion of the
								Terms, or have otherwise demonstrated inappropriate conduct, we
								reserve the right to (i) warn you via e-mail (to any e-mail
								address you have provided to us) that you have violated the
								Terms; (ii) notify and/or send any content you have provided to
								us to and/or fully cooperate with the proper law enforcement
								authorities for further action; and/or (iii) pursue any other
								action which we deem to be appropriate. If, as a result of the
								investigation, we believe that illegal activity has occurred, we
								reserve the right to refer the matter to, and to cooperate with,
								any and all applicable legal authorities. We are entitled,
								except to the extent prohibited by applicable law, to disclose
								any information or materials on or in One More Watches Properties in
								our possession in connection with your use of One More Watches
								Properties, to (i) comply with applicable laws, legal process or
								governmental request; (ii) enforce the Terms; (iii) respond to
								your requests for customer service; or (iv) protect the rights,
								property or personal safety of One More Watches, its users or the
								public, and to comply with the requests of all law enforcement
								or other government officials, as we in our sole discretion
								believe to be necessary or appropriate. We reserve the right, in
								our sole discretion, to immediately take any or all of the
								actions set forth herein without any notice or warning to you.
								You agree that all terminations shall be made in our sole
								discretion and that we shall not be liable to you or any
								third-party for enforcing this provision. If you want to
								terminate the Services provided by One More Watches, you may do so
								by (a) notifying One More Watches at any time and (b) closing your
								Account for all of the Services that you use. 
								Termination of all Services also includes deletion of
								your password and all related information, files and Content
								associated with or inside your Account (or any part thereof),
								including Your Content. Upon termination of any Service, your
								right to use such Service will automatically terminate
								immediately. You understand that any termination of Services may
								involve deletion of Your Content associated therewith from our
								live databases. One More Watches will not have any liability
								whatsoever to you for any suspension or termination, including
								for deletion of Your Content. All provisions of the Terms which
								by their nature should survive, shall survive termination of
								Services, including without limitation, ownership provisions,
								warranty disclaimers, and limitation of liability.
							</li>
						</ul>
					</li>
					<li>
						<strong>INTERNATIONAL USERS.  </strong> This Website can be accessed
						from countries around the world and may contain references to ONEMOREWATCHES
						Watches Properties and other content that are not available in your
						country. These references do not imply that One More Watches intends to
						introduce such One More Watches Properties or content in your country.
						One More Watches Properties are controlled and offered by ONEMOREWATCHES
						Watchesfrom its facilities in China. ONEMOREWATCHES
						Watches makes no representations that One More Watches Properties are
						appropriate or available for use in other locations. Those who
						access or use One More Watches Properties from other jurisdictions do so
						at their own volition and are responsible for compliance with local
						law.
					</li>
				</ul>

				<ul>
					<li>
						<strong>GENERAL PROVISIONS.</strong>

						<ul>
							<li>
								<strong>  20.1  </strong>
								<strong>
									<u>ELECTRONIC COMMUNICATIONS</u>.
								</strong>
								  The communications between you and One More Watches use electronic
								means, whether you visit One More Watches Properties or send ONEMOREWATCHES
								Watches e-mails, or whether One More Watches posts notices on ONEMOREWATCHES
								Watches Properties or communicates with you via e-mail. For
								contractual purposes, you (1) consent to receive communications
								from One More Watches in an electronic form; and (2) agree that all
								terms and conditions, agreements, notices, disclosures, and
								other communications that One More Watches provides to you
								electronically satisfy any legal requirement that such
								communications would satisfy if it were to be in writing. The
								foregoing does not affect your statutory rights.
							</li>
							<li>
								<strong>  20.2  </strong>
								<strong>
									<u>ASSIGNMENT</u>.
								</strong>
								  The Terms, and your rights and obligations hereunder, may not
								be assigned, subcontracted, delegated, or otherwise transferred
								by you without One More Watches prior written consent, and any
								attempted assignment, subcontract, delegation, or transfer in
								violation of the foregoing will be null and void.
							</li>
							<li>
								<strong>  20.3  </strong>
								<strong>
									<u>FORCE MAJEURE</u>.
								</strong>
								  One More Watches shall not be liable for any delay or failure to
								perform resulting from causes outside its reasonable control,
								including, but not limited to, acts of God, war, terrorism,
								riots, embargos, acts of civil or military authorities, fire,
								floods, accidents, strikes or shortages of transportation
								facilities, fuel, energy, labor or materials.
							</li>
							<li>
								<strong>  20.4  </strong>
								<strong>
									<u>COMPLIANCE</u>.
								</strong>
								  If you believe that One More Watches has not adhered to the Terms,
								please contact One More Watches by emailing us at
								support@onemorewatches.com. We will do our best to address your
								concerns. If you feel that your complaint has been addressed
								incompletely, we invite you to let us know for further
								investigation.
							</li>
							<li>
								<strong>  20.5  </strong>
								<strong>
									<u>NOTICE</u>.
								</strong>
								  Where One More Watches requires that you provide an e-mail
								address, you are responsible for providing One More Watches with
								your most current e-mail address. In the event that the last
								e-mail address you provided to One More Watches is not valid, or for
								any reason is not capable of delivering to you any notices
								required/ permitted by the Terms, One More Watches dispatch of the
								e-mail containing such notice will nonetheless constitute
								effective notice. 
							</li>
							<li>
								<strong>  20.6  </strong>
								<strong>
									<u>WAIVER</u>.
								</strong>
								  Any waiver or failure to enforce any provision of the Terms on
								one occasion will not be deemed a waiver of any other provision
								or of such provision on any other occasion.
							</li>
							<li>
								<strong>  20.7  </strong>
								<strong>
									<u>SEVERABILITY</u>.
								</strong>
								  If any provision of the Terms is, for any reason, held to be
								invalid or unenforceable, the other provisions of the Terms will
								remain enforceable, and the invalid or unenforceable provision
								will be deemed modified so that it is valid and enforceable to
								the maximum extent permitted by law.
							</li>
							<li>
								<strong>  20.8  </strong>
								<strong>
									<u>ENTIRE AGREEMENT</u>.
								</strong>
								  The Terms are the final, complete and exclusive agreement of
								the parties with respect to the subject matter hereof and
								supersedes and merges all prior discussions between the parties
								with respect to such subject matter.
							</li>
						</ul>
					</li>
				</ul>

				<Paragraph3>End of Terms</Paragraph3>
      </Block>
    </Layout>
	);
};
